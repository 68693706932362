import React from "react";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import { H2 } from "theme/components/atoms/Typography/Heading";
import CookieGrid from "./CookieGrid/CookieGrid";
import PrimaryButton from "theme/components/atoms/Button/PrimaryButton";
import Button from "theme/components/atoms/Button";
import H3 from "theme/components/atoms/Typography/Heading/H3";

const messages = defineMessages({
  allCookies: {
    id: "modules.Cookie.CookiePage.allCookies",
    defaultMessage: "Preferences for all services",
  },
  denyAll: {
    id: "modules.Cookie.CookiePage.denyAll",
    defaultMessage: "Deny all",
  },
  allowAll: {
    id: "modules.Cookie.CookiePage.allowAll",
    defaultMessage: "Allow all",
  },
});

const CookiePage = ({
  services,
  cookie,
  updateAuthorizations,
  setAllAuthorizations,
  finalizeConfiguration,
  allCookiesAreConfigured,
  intl,
  setStateServices,
}) => {
  return (
    <div className="cookie-page">
      <div className="cookie-page__title">
        <H2>
          <FormattedMessage
            id="modules.Cookie.CookiePage.title"
            defaultMessage="Managing your preferences on cookies"
          />
        </H2>
      </div>
      <div className="cookie-page__global-cookies">
        <div className="cookie-page__global-cookies__title">
          <H3>{intl.formatMessage(messages.allCookies)}</H3>
        </div>
        <div className="cookie-page__global-cookies__actions">
          <PrimaryButton
            onClick={() => {
              setAllAuthorizations(true);
              setStateServices({ service: "all", type: "allow" });
              finalizeConfiguration();
            }}
          >
            {intl.formatMessage(messages.allowAll)}
          </PrimaryButton>
          <Button
            onClick={() => {
              setAllAuthorizations(false);
              setStateServices({ service: "all", type: "deny" });
              finalizeConfiguration();
            }}
          >
            {intl.formatMessage(messages.denyAll)}
          </Button>
        </div>
      </div>
      <div className="cookie-page__description">
        <FormattedMessage
          id="modules.Cookie.CookiePage.description"
          defaultMessage="Some features of this site rely on services offered by third-party sites. These features deposit cookies allowing these sites to trace your navigation. These cookies are only deposited if you give your consent. You can inform yourself about the nature of the cookies deposited, accept or reject them either globally for the entire site and all services, or service by service."
        />
      </div>
      <div className="cookie-page__children">
        {services.map((type) => (
          <CookieGrid
            key={type.title}
            title={type.title}
            description={type.description}
            services={type.services}
            cookie={cookie}
            updateAuthorizations={updateAuthorizations}
            setStateServices={setStateServices}
          />
        ))}
        <div className="cookie-page__children__actions">
          <PrimaryButton
            onClick={() => {
              finalizeConfiguration();
            }}
            state={!allCookiesAreConfigured ? "disabled" : undefined}
          >
            <FormattedMessage
              id="modules.Cookie.CookiePage.finalize"
              defaultMessage="Finish"
            />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

CookiePage.propTypes = {
  cookie: PropTypes.object,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      title: CookieGrid.propTypes.title,
      description: CookieGrid.propTypes.description,
      service: CookieGrid.propTypes.services,
    })
  ),
  updateAuthorizations: PropTypes.func.isRequired,
  setAllAuthorizations: PropTypes.func.isRequired,
};

export default injectIntl(CookiePage);
