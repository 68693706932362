import React from "react";
import Modal from "theme/components/organisms/Modal";
import CookiePage from "theme/modules/Cookie/CookiePage";

const CookieModal = ({
  setAllAuthorizations,
  allCookiesAreConfigured,
  isOpen,
  services,
  cookie,
  updateAuthorizations,
  finalizeConfiguration,
  onRequestClose,
  setStateServices,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    appearance="cookie"
    contentLabel="Cookie Notice Window"
    scrollText={true}
  >
    <CookiePage
      services={services}
      cookie={cookie}
      updateAuthorizations={updateAuthorizations}
      setAllAuthorizations={setAllAuthorizations}
      allCookiesAreConfigured={allCookiesAreConfigured}
      finalizeConfiguration={finalizeConfiguration}
      setStateServices={setStateServices}
    />
  </Modal>
);

export default CookieModal;
