import React from "react";
import { defineMessages } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import EnhanceCookieLine from "./EnhanceCookieLine";
import { BodyParagraph } from "theme/components/atoms/Typography/Body";
import Button from "theme/components/atoms/Button";
import Link from "theme/components/atoms/Typography/Link";
import H3 from "theme/components/atoms/Typography/Heading/H3";

const messages = defineMessages({
  deny: {
    id: "modules.Cookie.CookiePage.CookieLine.deny",
    defaultMessage: "Deny",
  },
  allow: {
    id: "modules.Cookie.CookiePage.CookieLine.allow",
    defaultMessage: "Allow",
  },
  viewSite: {
    id: "modules.Cookie.CookiePage.CookieLine.viewSite",
    defaultMessage: "View site",
  },
  readMore: {
    id: "modules.Cookie.CookiePage.CookieLine.readMore",
    defaultMessage: "Read more",
  },
});

const CookieLine = ({
  service,
  toggleReadMore,
  readMoreIsVisible,
  setStatus,
  intl,
  status,
  setStateServices,
}) => (
  <div
    className={classnames("cookie-line", {
      "cookie-line--configured": status !== undefined,
    })}
  >
    <div className="cookie-line__info">
      <H3>{service.title}</H3>
      <div className="cookie-line__info__description">
        {service.description && (
          <Button appearance="link" onClick={toggleReadMore}>
            {intl.formatMessage(messages.readMore)}
          </Button>
        )}
        {service.link && (
          <Link to={service.link} external>
            {intl.formatMessage(messages.viewSite)}
          </Link>
        )}
        {readMoreIsVisible && (
          <BodyParagraph>{service.description}</BodyParagraph>
        )}
      </div>
    </div>
    <div className="cookie-line__actions">
      <div>
        <Button
          appearance={status === true ? "primary" : undefined}
          onClick={() => {
            setStatus(true);
            setStateServices({ service: service.name, type: "allow" });
          }}
        >
          {intl.formatMessage(messages.allow)}
        </Button>
      </div>
      <div>
        <Button
          appearance={status === false ? "primary" : undefined}
          onClick={() => {
            setStatus(false);
            setStateServices({ service: service.name, type: "deny" });
          }}
        >
          {intl.formatMessage(messages.deny)}
        </Button>
      </div>
    </div>
  </div>
);

const EnhancedCookieLine = EnhanceCookieLine()(CookieLine);

EnhancedCookieLine.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default EnhancedCookieLine;
