import React from "react";
import Wysiwyg from "theme/modules/WysiwygV2";

const BottomBar = ({ cmsBlock }) => {
  if (!cmsBlock) return null;

  return <Wysiwyg content={cmsBlock.contentWysiwyg} />
};

export default BottomBar;
