import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import Button from "theme/components/atoms/Button";
import Link from "theme/components/atoms/Typography/Link";
import FormActions from "theme/components/molecules/Form/FormActions";
import { Grid } from "theme/components/templates/Grid";
import Cell from "theme/components/templates/Grid/Cell";

const messages = defineMessages({
  allowCookies: {
    id: "modules.Cookie.CookieNotice.allowCookies",
    defaultMessage: "Allow all the cookies",
  },
  denyCookies: {
    id: "modules.Cookie.CookieNotice.denyCookies",
    defaultMessage: "Deny all the cookies",
  },
  readMore: {
    id: "modules.Cookie.CookieNotice.readMore",
    defaultMessage: "Read more",
  },
  description: {
    id: "modules.Cookie.CookieNotice.description",
    defaultMessage:
      'Kaporal uses cookies and other technologies to ensure the reliability and security of our sites, to measure their performance and to offer an individualised shopping experience and personalised advertising. By selecting "I agree", you agree to this and consent to our sharing this information with third parties. In the event that you refuse we will only use the necessary cookies and you will unfortunately not be able to receive any personalised content. Select "Set my preferences" for more details and to manage your options. You can set or change your preferences at any time. You can find more information in our {termsAndConditionsLink}',
  },
  descriptionTermsAndConditionsLink: {
    id: "modules.Cookie.CookieNotice.description.termsAndConditionsLink",
    defaultMessage: "Privacy Policy",
  },
});

const TermAndConditionsLink = () => {
  return (
    <Link to={"/politique-de-confidentialite"}>
      <FormattedMessage {...messages.descriptionTermsAndConditionsLink} />
    </Link>
  );
};

const CookieNotice = ({
  authorizeAll,
  denyAll,
  intl,
  showModal,
  setStateServices,
}) => {
  return (
    <div className="cookie-notice">
      <Grid>
        <Cell size={["small-only-100"]}>
          <div>
            <FormattedMessage
              {...messages.description}
              values={{
                termsAndConditionsLink: <TermAndConditionsLink />,
              }}
            />
          </div>
          <FormActions appearance={"start"}>
            <Button appearance={"link"} onClick={() => showModal(true)}>
              {intl.formatMessage(messages.readMore)}
            </Button>
          </FormActions>
        </Cell>
        <Cell size={["small-only-100"]}>
          <FormActions appearance="vertical">
            <Button
              onClick={() => {
                authorizeAll();
                setStateServices({ service: "all", type: "allow" });
              }}
              appearance="cookie"
            >
              {intl.formatMessage(messages.allowCookies)}
            </Button>
            {denyAll ? (
              <Button
                onClick={() => {
                  denyAll();
                  setStateServices({ service: "all", type: "deny" });
                }}
                appearance="cookie"
              >
                {intl.formatMessage(messages.denyCookies)}
              </Button>
            ) : null}
          </FormActions>
        </Cell>
      </Grid>
    </div>
  );
};

export default injectIntl(CookieNotice);
