import { compose, withHandlers } from "recompose";
import { withApollo, graphql } from "react-apollo";
import { withRouter } from "react-router";
import { setRefreshing } from "theme/modules/Router/useRefreshing";

const withStoreSelectorHandler = ({ StoreViewUrlQuery }) => {
  if (process.env.SERVER) {
    return (BaseComponent) => BaseComponent;
  }

  return compose(
    withRouter,
    withApollo,
    withHandlers({
      setStoreView: (props) => (value) => {
        props.client
          .query({
            query: StoreViewUrlQuery,
            variables: {
              url: props.location.pathname + props.location.search,
              otherShop: value,
            },
          })
          .then(({ data }) => {
            if (data.shop.translatedUrl) {
              if (document && document.location) {
                document.location.href = data.shop.translatedUrl;
                setRefreshing(props.history, props.location)(true);
              }
            }
          });
      },
    })
  );
};

const EnhanceStoreViewSelector = ({ AvailableStoreQuery, StoreViewUrlQuery }) =>
  compose(
    graphql(AvailableStoreQuery),
    withStoreSelectorHandler({ StoreViewUrlQuery })
  );

export default EnhanceStoreViewSelector;
