import React from "react";
import PropTypes from "prop-types";

import { H3 } from "theme/components/atoms/Typography/Heading";
import CookieLine from "../CookieLine";

const CookieGrid = ({
  title,
  description,
  services,
  cookie,
  updateAuthorizations,
  setStateServices,
}) => (
  <div className="cookie-grid">
    <div className="cookie-grid__title">
      <H3>{title}</H3>
    </div>
    {description && (
      <div className="cookie-grid__description">{description}</div>
    )}
    <div className="cookie-grid__children">
      {services.map((service) => (
        <CookieLine
          key={service.name}
          service={service}
          status={cookie && cookie[service.name]}
          updateCookie={(status) => updateAuthorizations(service, status)}
          setStateServices={setStateServices}
        />
      ))}
    </div>
  </div>
);

CookieGrid.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cookie: PropTypes.object,
  services: PropTypes.arrayOf(CookieLine.propTypes.service),
  updateAuthorizations: PropTypes.func.isRequired,
};

export default CookieGrid;
