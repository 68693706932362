import { compose, lifecycle, mapProps } from "recompose";
import { graphql } from "react-apollo";
import DefaultMagentoEventConfigQuery from "./DefaultMagentoEventConfigQuery.gql";
import trackEvent from "theme/modules/Analytics/trackEvent";

const initMagentoEvent =(config) => {
  config &&
  trackEvent("Rec init", {
    initData: config,
  });
}

const magentoEventInit = () =>
  compose(
    graphql(DefaultMagentoEventConfigQuery, {
      props: ({ data }) => ({
        config: !data.loading && data,
      }),
    }),
    lifecycle({
      componentDidMount() {
        initMagentoEvent(this.props.config.fc_rec_config);
      },
      componentDidUpdate(prevProps, prevState) {
        if (prevProps?.shop?.id !== this.props?.shop?.id) {
          initMagentoEvent(this.props.config.fc_rec_config);
        }
      },
    }),
    mapProps((props) => props)
  );

export default magentoEventInit;
