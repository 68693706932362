import React from "react";
import Wysiwyg from "theme/modules/WysiwygV2";

const Reinsurance = (props) => {
  return (
    <section className="reinsurance">
      {props.cmsBlock && <Wysiwyg content={props.cmsBlock.contentWysiwyg} />}
    </section>
  );
};

export default Reinsurance;
