import React, { useReducer, useEffect, useState } from "react";
import { compose, lifecycle } from "recompose";
import { FormattedMessage } from "react-intl";
import { initAnalytics } from "web/core/analytics";
import withConsentCookies from "web/core/analytics/withConsentCookies";
import CookieModal from "theme/modules/Cookie/CookieModal";
import Button from "theme/components/atoms/Button";
import CookieNotice from "theme/modules/Cookie/CookieNotice";

const CookieLink = ({
  authorizeAll,
  denyAll,
  setAllAuthorizations,
  allCookiesAreConfigured,
  services,
  cookie,
  updateAuthorizationsCookie,
  finalizeConfiguration,
  hasConsent,
  showModal,
  modalIsVisible,
}) => {
  // Save configuration on request close.
  const onRequestClose = () => {
    showModal(false);
    finalizeConfiguration();
  };

  const serviceNames = [];
  services.forEach((service) => {
    if (service.services.length > 0) {
      return service.services.forEach((service) => {
        serviceNames.push(service.name);
      });
    }
  });
  const [noFirstInteraction, setFirstIntercation] = useState(true);

  const stateServicesReducer = (state, action) => {
    let allow = state.allow;
    let deny = state.deny;

    if (noFirstInteraction) {
      setFirstIntercation(false);
    }
    switch (action.type) {
      case "deny":
        if (action.service === "all") {
          deny = serviceNames;
          allow = [];
        } else {
          deny = [...deny, action.service];
          allow = allow.filter((service) => service !== action.service);
        }
        break;
      default:
        if (action.service === "all") {
          allow = serviceNames;
          deny = [];
        } else {
          allow = [...allow, action.service];
          deny = deny.filter((service) => service !== action.service);
        }
    }

    return {
      allow,
      deny,
    };
  };
  const defaultServiceAllow = cookie
    ? Object.keys(cookie).filter((key) => cookie[key] === true)
    : [];
  const defaultServiceDeny = cookie
    ? Object.keys(cookie).filter((key) => cookie[key] === false)
    : [];

  const [stateServices, setStateServices] = useReducer(stateServicesReducer, {
    allow: defaultServiceAllow,
    deny: defaultServiceDeny,
  });

  useEffect(() => {
    if (
      noFirstInteraction ||
      (!stateServices.allow.length && !stateServices.deny.length)
    ) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userConsents: stateServices.allow,
      event: "UserConsentAllow",
    });
    window.dataLayer.push({
      userConsents: stateServices.deny,
      event: "UserConsentDeny",
    });
  }, [stateServices, noFirstInteraction]);

  return (
    <div className="cookie-link">
      <Button appearance="link" onClick={() => showModal(true)}>
        <FormattedMessage
          id="modules.Cookie.CookieLink.link"
          defaultMessage="Privacy"
        />
      </Button>
      {services && !hasConsent && (
        <CookieNotice
          showModal={showModal}
          authorizeAll={authorizeAll}
          denyAll={denyAll}
          setStateServices={setStateServices}
        />
      )}
      <CookieModal
        isOpen={modalIsVisible}
        onRequestClose={onRequestClose}
        services={services}
        cookie={cookie}
        updateAuthorizations={updateAuthorizationsCookie}
        setAllAuthorizations={setAllAuthorizations}
        allCookiesAreConfigured={allCookiesAreConfigured}
        finalizeConfiguration={onRequestClose}
        setStateServices={setStateServices}
      />
    </div>
  );
};

export default compose(
  withConsentCookies(),
  lifecycle({
    componentDidMount() {
      if (
        this.props.allCookiesAreConfigured === false &&
        this.props.hasConsent
      ) {
        this.props.cookies.set("hasConsent", "", {
          path: "/",
          expires: new Date(0),
        });
        if (!process.env.SERVER && this.props.hasConsent) {
          window.location.reload();
        }
      }
      initAnalytics(this.props.cookie);
    },
  })
)(CookieLink);
