import React from "react";
import { FormattedMessage } from "react-intl";
import Breakpoints from "theme/components/atoms/Breakpoints";
import Link from "theme/components/atoms/Typography/Link";
import MediaQuery from "theme/components/helpers/MediaQuery";
import CmsBlock from "theme/modules/CmsBlock";
import Newsletter from "theme/modules/Newsletter";
import Reinsurance from "theme/modules/Reinsurance";
import SocialMedia from "theme/modules/SocialMedia/SocialMedia";
import SubHeader from "theme/modules/User/SubHeader/SubHeader.js";
import BottomBar from "./BottomBar";
import EnhanceFooter from "./EnhanceFooter";
import FooterConfigQuery from "./FooterConfigQuery.gql";
import FooterQuery from "./FooterQuery.gql";
import Legislation from "./Legislation";
import Logo from "theme/components/atoms/Logo";

const Footer = ({ me, footerConfig }) => {

  const footer_image = (me.loading) ? null : me.cmsBlock?.find((el) => el?.identifier === "footer_image");

  return (
    <footer className="footer">
      <div className="footer__top">
        {!me.loading &&  <Newsletter user={me?.me}/>}
        {
          me.cmsBlock &&
          <Reinsurance
            cmsBlock={
              !me.loading &&
              me.cmsBlock.find((el) => el?.identifier === "footer-reassurance")
            }
          />
        }
      </div>
      <div className="footer__main">
        <MediaQuery maxWidth={Breakpoints.tablet - 1}>
          <div className="brand__logo">  
            <Link to="/">
              <Logo color={"white"} />
            </Link>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={Breakpoints.desktop}>
          <div className="footer__image">
            {footer_image && (<CmsBlock cmsBlock={footer_image} />)}
          </div>
        </MediaQuery>
        {
          me.cmsBlock &&
          <BottomBar
            cmsBlock={
              !me.loading &&
              me.cmsBlock.find((el) => el?.identifier === "footer_links")
            }
          />
        }
      </div>
      <div className="footer__bottom">
      <div className="footer__bottom-left">
        <div className="footer__store-view-selector">
          <span>  
            <FormattedMessage
              id="layouts.Footer.languages"
              defaultMessage="Language: "
          />
          </span>  
          <SubHeader />
        </div>
        <MediaQuery minWidth={Breakpoints.tablet}>
          <Legislation copyright={footerConfig?.copyright} />
        </MediaQuery>
      </div>
      <MediaQuery minWidth={Breakpoints.tablet}>
          <div className="brand__logo">  
            <Link to="/">
              <Logo color={"white"} />
            </Link>
          </div>
        </MediaQuery>
        {
          me.cmsBlock &&
          <SocialMedia
            cmsBlock={
              !me.loading &&
              me.cmsBlock.find((el) => el?.identifier === "footer_social_networks")
            }
          />
        }
        <MediaQuery maxWidth={Breakpoints.tablet - 1}>
          <Legislation copyright={footerConfig?.copyright} />
        </MediaQuery>
      </div>
    </footer>
  );
};

const SmartFooter = EnhanceFooter({
  UserQuery: FooterQuery,
  FooterConfigQuery,
})(Footer);

export default SmartFooter;
