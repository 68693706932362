import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import branchServerClient from "web/core/branchServerClient";

const EnhanceFooter = ({ UserQuery, FooterConfigQuery }) =>
  compose(
        graphql(FooterConfigQuery, {
          name: "footerConfig",
        }),
      withProps({
        me: {
          loading: true,
        },
      }),
      graphql(UserQuery, {
        name: "me",
      })

  );

export default EnhanceFooter;
